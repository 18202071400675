import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ThemeProvider from '@mui/system/ThemeProvider';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FAQ from './components/FAQ';
import NavLink from './components/NavLink';
import StoreButtons from './components/StoreButtons';
import * as color from './constants/colors';
import theme, { mixinFontSizes } from './constants/theme';
import { isMobilePlatform } from './utils';

const styles = {
  body: {
    p: { xs: 0 },
  },
  logo: {
    height: 42,
  },
  primaryText: {
    color: color.colorSchemeLight.primary,
  },
  sectionDark: {
    backgroundColor: color.colorSchemeLight.primary,
    color: color.colorSchemeLight.onPrimary,
  },
  sectionLight: {
    backgroundColor: color.colorSchemeLight.surface,
    color: color.colorSchemeLight.onSurface,
  },
  header: {
    alignItems: 'center',
    backgroundColor: color.colorSchemeLight.surface,
    color: color.colorSchemeLight.onSurface,
    display: 'flex',
    height: 88,
    justifyContent: 'space-between',
    pl: 4,
    pr: 2,
  },
  headerLinks: {
    alignItems: { xs: 'end', sm: 'center' },
    color: color.colorSchemeLight.onSurface,
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    justifyContent: 'space-between',
  },
  headerDivider: {
    backgroundColor: color.colorSchemeLight.onSurface,
    display: { xs: 'none', sm: 'block' },
    width: '1px',
    height: 28,
  },
  headerLink: {
    px: 2,
    py: { xs: 0.4, sm: 0 },
  },
  sectionText: {
    py: 1.25,
  },
  sectionNavLink: {
    color: 'primary.main',
  },
  sectionLink: {
    fontWeight: 500,
    letterSpacing: '0.7px',
  },
  surfaceText: {
    color: color.colorSchemeLight.onSurface,
  },
  section1: {
    backgroundColor: { xs: color.colorSchemeLight.surface, md: color.colorSchemeLight.primary },
    color: { xs: color.colorSchemeLight.onSurface, md: color.colorSchemeLight.onPrimary },
  },
  s1Subtitle: {
    maxWidth: { md: 400 },
  },
  s1Container: {
    backgroundColor: color.colorSchemeLight.primary,
    backgroundImage: 'url("images/dog.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: { md: 525, lg: 600 },
  },
  s1Grid1: {
    p: 4,
  },
  s1ImageBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    mb: 4.75,
  },
  s1Dog: {
    height: 320,
  },
  s1DogMobileM: {
    height: 300,
  },
  s1DogMobileS: {
    height: 260,
  },
  s1StoreButtons: {
    justifyContent: { xs: 'center', sm: 'flex-start' },
    py: 2,
  },
  s1FooterContainer: {
    alignItems: 'center',
    backgroundColor: color.colorSchemeLight.secondary,
    color: color.colorSchemeLight.onSecondary,
    display: { xs: 'block', md: 'flex' },
    justifyContent: 'center',
    mt: { md: 4.75 },
  },
  s1FooterItem: {
    p: 2,
  },
  s1FooterDivider: {
    backgroundColor: color.colorSchemeLight.onPrimary,
    width: { xs: 60, md: 3 },
    height: { xs: 3, md: 40 },
    my: { xs: 0, md: 2 },
    mx: { xs: 'auto', md: 0 },
  },
  s1FooterTrim: {
    py: { xs: 1.5, md: 2 },
  },
  s2Grid1: {
    px: 4,
    pt: { xs: 2, md: 4 },
    pb: 4,
  },
  s2Grid2: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    p: 4,
  },
  s2GetStarted: {
    pb: 5,
  },
  s3Grid1: {
    alignItems: { xs: 'center', md: 'start' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    p: 4,
  },
  s3Grid2: {
    p: 4,
  },
  s4Grid2: {
    p: 4,
  },
  s4ImageBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    pt: { xs: 0, md: 8 },
  },
  s4ImageBoxMdDown: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    pb: 5.5,
    pt: 1,
  },
  s4Sofa: {
    height: { xs: 230, md: 240, lg: 300 },
  },
  s4SofaMobileM: {
    height: 200,
  },
  s4SofaMobileS: {
    height: 180,
  },
  s5Section: {
    mt: { xs: 0, md: 1.5 },
  },
  s5Grid1: {
    p: 4,
  },
  s5Title: {
    pt: 4,
  },
  s5ImageBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'start',
    mt: { xs: 0, md: 12 },
  },
  s5Couple: {
    mb: { xs: 4, md: 0 },
  },
  s5CoupleDefault: {
    height: { xs: 230, md: 250, lg: 320 },
  },
  s5CoupleMobileM: {
    height: 200,
  },
  s5CoupleMobileS: {
    height: 180,
  },
  s6Grid1: {
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: { xs: 'center', md: 'start' },
  },
  s6Grid2: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    pt: { xs: 1, md: 0 },
    pb: { xs: 4, md: 0 },
  },
  s6Title: {
    pb: { xs: 2.5, md: 3 },
  },
  sTeamSection: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    pt: { xs: 4, md: 8 },
    pb: { xs: 3, md: 6 },
  },
  sTeamTitle: {
    ...mixinFontSizes({ xs: '0.875rem', sm: '1rem', lg: '1.125rem' }),
    color: color.colorSchemeLight.primary,
    fontWeight: 500,
    letterSpacing: { xs: '3px', lg: '4px' },
    lineHeight: { xs: '1.3', lg: '4px' },
    pb: { xs: 4, lg: 5.5 },
  },
  sTeamSubtitleBox: {
    maxWidth: { xs: '80%', lg: '70%' },
    pb: { xs: 3, lg: 6 },
  },
  sTeamSubtitle: {
    fontWeight: 400,
    letterSpacing: '0.7px',
    lineHeight: '1.3',
  },
  sTeamBox: {
    pt: 5.5,
    pb: { xs: 5, lg: 6.5 },
  },
  sTeamImage: {
    borderRadius: '8px',
    mb: { xs: 4, lg: 2.25 },
    width: { xs: 230, md: 250, lg: 260 },
  },
  sTeamImageTitle: {
    ...mixinFontSizes({ xs: '1.125rem', sm: '1.25rem', lg: '1.375rem' }),
    letterSpacing: '0.7px',
  },
  sTeamImageSubtitle: {
    fontWeight: 400,
  },
  sTeamImageBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    mb: { xs: 2.5, lg: 0 },
  },
  sTeamTextBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    textAlign: { xs: 'center', lg: 'inherit' },
    maxWidth: { lg: '90%' },
    px: { xs: 3, sm: 4, md: 8, lg: 0 },
  },
  sTeamText1: {
    ...mixinFontSizes({ xs: '1rem', sm: '1.25rem', lg: '1.3125rem' }),
  },
  sTeamText2: {
    pt: { lg: 0 },
  },
  sClinicSubtitle: {
    maxWidth: { md: 400 },
  },
  sClinicContainer: {
    pb: { md: 2 },
  },
  sClinicTextBox: {
    my: { md: 1 },
  },
  sClinicGrid: {
    px: 4,
    py: { xs: 1.5, md: 4 },
  },
  sClinicGridMobileM: {
    py: { xs: 0 },
  },
  sClinicGrid1: {
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: { xs: 'center', md: 'start' },
  },
  sClinicGrid2: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    pb: { xs: 4, md: 0 },
  },
  sClinicImageBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'start',
    mt: { md: 6 },
    mb: { xs: 0.5, md: 2.5 },
  },
  sClinicCouple: {
    mb: { xs: 4, md: 0 },
  },
  sClinicCoupleDefault: {
    height: { xs: 230, md: 250, lg: 320 },
  },
  sClinicCoupleMobileM: {
    height: 260,
  },
  sClinicCoupleMobileS: {
    height: 224,
  },
  sClinicTitle: {
    pb: { xs: 2.5, md: 3 },
  },
  footer: {
    alignItems: { xs: 'center', md: 'start' },
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: { xs: 'center', md: 'space-between' },
    minHeight: 124,
    px: { xs: 0, md: 7.5 },
    pt: 8,
    pb: 4,
  },
  footerContact: {
    alignItems: { xs: 'center', md: 'start' },
    display: 'flex',
    justifyContent: { xs: 'center', md: 'start' },
    flexDirection: 'column',
    pt: { xs: 2.5, md: 0 },
  },
  footerLegalLinks: {
    alignItems: { xs: 'center', md: 'end' },
    display: 'flex',
    flexDirection: { xs: 'row', md: 'column' },
    justifyContent: { xs: 'center', md: 'start' },
    pt: { xs: 2.5, md: 0 },
    pb: { xs: 4, md: 0 },
  },
  footerLegalText: {
    px: 2,
  },
};

function EarlyDetection() {
  return (
    <Box>
      <Typography sx={styles.sectionText} variant="h3">
        Early Detection Can Improve Cancer Outcomes
      </Typography>
      <Typography sx={styles.sectionText} variant="body1">
        Cancer treatments and survival improve the earlier a cancer is diagnosed. Cancer screening can detect cancer
        before symptoms develop.
      </Typography>
    </Box>
  );
}

function AboutChek() {
  return (
    <Box>
      <Typography sx={styles.sectionText} variant="h3">
        About CHEK
      </Typography>
      <Typography sx={styles.sectionText} variant="body1">
        Chek Health is an Alberta based business founded by physicians who are passionate about providing quality health
        care, accessible to all individuals.
      </Typography>
      <Typography sx={styles.sectionText} variant="body1">
        Everyone should have the peace of mind that comes with being up to date on Canadian recommended cancer screening
        guidelines. As early detection is the best way to improve cancer outcomes, we encourage you to use our health
        services.
      </Typography>
      <Typography sx={styles.sectionText} variant="body1">
        We commit to build on our foundation in a relentless pursuit of raising the bar for health care and access to
        physicians.
      </Typography>
    </Box>
  );
}

function App() {
  const [path, setPath] = useState(null);
  const [tag, setTag] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const breakpointMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const breakpointLgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const mobileS = useMediaQuery('(max-width:320px)');
  const mobileM = useMediaQuery('(max-width:375px)');

  useEffect(() => {
    if (location.hash !== null && location.hash.length > 0) {
      setTag(location.hash);
      location.hash = '';
    }
    if (path === null) {
      const pathname = (() => {
        // On mobile redirect clinic to home, so always initialize to home
        if (isMobilePlatform()) {
          return '/home';
        }
        // Initialize to home unless explicitly request clinic
        // (i.e. tap button in Chek Update email)
        return location.pathname === '/clinic' ? location.pathname : '/home';
      })();
      navigate(pathname, { replace: true });
      setPath(pathname);
      return;
    }
    if (path !== location.pathname) {
      // On mobile redirect clinic to home
      setPath(location.pathname === '/clinic' && isMobilePlatform() ? '/home' : location.pathname);
      return;
    }
    if (location.state !== null) {
      const { scrollTo } = location.state;
      if (scrollTo !== undefined && scrollTo !== null) {
        window.scrollTo({ top: scrollTo, left: 0, behavior: 'smooth' });
      }
    }
    if (tag !== null) {
      if (document.getElementById(tag.slice(1))) {
        document.querySelector(tag).scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      setTag(null);
    }
  }, [location, navigate, path, tag]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg" sx={styles.body}>
        <Box sx={styles.header}>
          <NavLink
            handleClick={
              path === '/home'
                ? () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                : () => navigate('/home', { state: { scrollTo: 0 } })
            }
            title="Chek Logo"
          >
            <Box component="img" src="images/logo.svg" alt="chek logo" sx={styles.logo} />
          </NavLink>
          <Box sx={styles.headerLinks}>
            <NavLink
              handleClick={() => {
                const newTag = '#aboutUs';
                navigate('/home', { state: { tag: newTag } });
                setTag(newTag);
              }}
              sx={styles.surfaceText}
              title="About Chek Health"
            >
              <Typography align="center" sx={styles.headerLink} variant="body2">
                About Us
              </Typography>
            </NavLink>
            <Box sx={styles.headerDivider} />
            <NavLink
              handleClick={
                path === '/team'
                  ? null
                  : () => {
                      navigate('/team');
                    }
              }
              sx={styles.surfaceText}
              title="Chek Health Team"
            >
              <Typography align="center" sx={styles.headerLink} variant="body2">
                Our Team
              </Typography>
            </NavLink>
            <Box sx={styles.headerDivider} />
            <NavLink
              handleClick={() => {
                const newTag = '#contactUs';
                navigate('/home', { state: { tag: newTag } });
                setTag(newTag);
              }}
              sx={styles.surfaceText}
              title="Contact Chek Health"
            >
              <Typography align="center" sx={styles.headerLink} variant="body2">
                Contact Us
              </Typography>
            </NavLink>
          </Box>
        </Box>
        {path === '/home' && (
          <>
            <Box sx={styles.section1}>
              <Grid container sx={(!breakpointMdDown && styles.s1Container) || {}}>
                <Grid item xs={12} md={7} sx={styles.s1Grid1}>
                  <Typography sx={[styles.sectionText, styles.surfaceText]} variant="h2">
                    Cancer Screening Simplified
                  </Typography>
                  <Typography sx={[styles.sectionText, styles.surfaceText, styles.s1Subtitle]} variant="body1">
                    We provide personalized cancer screening plans from a licensed physician. Stay up to date with
                    recommended cancer screening tests with Chek.
                  </Typography>
                  <StoreButtons sx={styles.s1StoreButtons} />
                </Grid>
                <Grid item xs={12} md={5} />
              </Grid>
              {breakpointMdDown && (
                <Box sx={styles.s1ImageBox}>
                  <Box
                    component="img"
                    src="images/dog_small.png"
                    alt="walking dog"
                    sx={(mobileS && styles.s1DogMobileS) || (mobileM && styles.s1DogMobileM) || styles.s1Dog}
                  />
                </Box>
              )}
              <Box sx={styles.s1FooterContainer}>
                <Typography align="center" sx={styles.s1FooterItem} variant="subtitle1">
                  Complete a Questionnaire
                </Typography>
                <Box sx={styles.s1FooterDivider} />
                <Typography align="center" sx={styles.s1FooterItem} variant="subtitle1">
                  Receive Test Requisitions
                </Typography>
                <Box sx={styles.s1FooterDivider} />
                <Typography align="center" sx={styles.s1FooterItem} variant="subtitle1">
                  Get Your Results
                </Typography>
              </Box>
              <Box sx={styles.s1FooterTrim} />
            </Box>
            <Box sx={styles.sectionLight}>
              <Grid container>
                <Grid item xs={12} md={6} sx={styles.s2Grid1}>
                  {breakpointMdDown && <EarlyDetection />}
                  <Typography sx={styles.sectionText} variant="h6">
                    No Appointments.
                  </Typography>
                  <Typography sx={styles.sectionText} variant="body1">
                    Skip the hassle of doctor&apos;s appointments with an easy to use, 24/7 health service portal.
                  </Typography>
                  <Typography sx={styles.sectionText} variant="h6">
                    No Cost.
                  </Typography>
                  <Typography sx={styles.sectionText} variant="body1">
                    No fees or subscriptions. The app is available to all individuals eligible for Alberta Health Care.
                  </Typography>
                  <Typography sx={styles.sectionText} variant="h6">
                    Stay Up To Date.
                  </Typography>
                  <Typography sx={styles.sectionText} variant="body1">
                    Stay up to date with recommended cancer screening for breast, cervical, colon and lung cancers.
                  </Typography>
                  <Typography sx={styles.sectionText} variant="body2">
                    * Disclaimer: Chek should not be used if you are experiencing any signs or symptoms of a potential
                    health issue. Chek is not a substitute for seeing a physician. Chek is not intended for the
                    diagnosing of diseases or other conditions, or for curing, treating or preventing disease or cancer.
                  </Typography>
                </Grid>
                {!breakpointMdDown && (
                  <Grid item xs={12} md={6} sx={styles.s2Grid2}>
                    <EarlyDetection />
                    <StoreButtons />
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box sx={styles.sectionLight}>
              <Grid container>
                <Grid item xs={12} md={6} sx={[styles.s3Grid1, breakpointMdDown && styles.sectionDark]}>
                  <Typography sx={[styles.sectionText, styles.s2GetStarted]} variant="h3">
                    Get Started Today
                  </Typography>
                  {breakpointMdDown && <StoreButtons />}
                </Grid>
                <Grid item xs={12} md={6} sx={styles.s3Grid2}>
                  <Typography sx={styles.sectionText} variant="h6">
                    Tell Us About Your Medical History.
                  </Typography>
                  <Typography sx={styles.sectionText} variant="body1">
                    Complete a short questionnaire, only information relevant to your cancer screening is collected. We
                    will provide you with your cancer screening plan.
                  </Typography>
                  <Typography sx={styles.sectionText} variant="h6">
                    Get Tested.
                  </Typography>
                  <Typography sx={styles.sectionText} variant="body1">
                    Receive requisitions, referrals and instruction for recommended cancer tests when they are due. We
                    will remind you if any recommended tests are not completed.
                  </Typography>
                  <Typography sx={styles.sectionText} variant="h6">
                    Receive Your Results.
                  </Typography>
                  <Typography sx={styles.sectionText} variant="body1">
                    Your results are reviewed by a physician and communicated to you with clear next steps.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box id="aboutUs" sx={styles.sectionDark}>
              <Grid container>
                <Grid item xs={12} md={6} sx={styles.s4ImageBox}>
                  {!breakpointMdDown && <Box component="img" src="images/sofa.png" alt="couple" sx={styles.s4Sofa} />}
                </Grid>
                <Grid item xs={12} md={6} sx={styles.s4Grid2}>
                  <AboutChek />
                  <NavLink
                    handleClick={() => {
                      navigate('/team', { state: { scrollTo: 0 } });
                    }}
                    sx={styles.sectionNavLink}
                    title="Chek Health Team"
                  >
                    <Typography sx={[styles.sectionText, styles.sectionLink]} variant="body1">
                      Chek Team
                    </Typography>
                  </NavLink>
                </Grid>
              </Grid>
              {breakpointMdDown && (
                <Box sx={styles.s4ImageBoxMdDown}>
                  <Box
                    component="img"
                    src="images/sofa.png"
                    alt="couple"
                    sx={(mobileS && styles.s4SofaMobileS) || (mobileM && styles.s4SofaMobileM) || styles.s4Sofa}
                  />
                </Box>
              )}
            </Box>
            <Box id="faq" sx={[styles.sectionLight, styles.s5Section]}>
              <Typography align="center" sx={styles.s5Title} variant="h3">
                Our FAQs
              </Typography>
              <Grid container>
                <Grid item xs={12} md={6} sx={styles.s5Grid1}>
                  <FAQ question="Is Chek available in my province?" answer="">
                    <Typography variant="body1">
                      Chek Health is currently only operating in Alberta. You must have a valid Alberta Health Care
                      number to receive services. We plan on expanding to other provinces in the near future.
                    </Typography>
                  </FAQ>
                  <FAQ question="Who has access to my information?">
                    <Typography variant="body1">
                      Only Chek Health staff and physicians will be able to view the information you provide us. We use
                      the latest technology to store and secure your information. Please see our{' '}
                      <Link
                        aria-label="Privacy Policy"
                        href="/privacy-policy"
                        rel="noopener"
                        title="Privacy Policy"
                        sx={styles.primaryText}
                      >
                        Privacy Policy
                      </Link>{' '}
                      for more information.
                    </Typography>
                  </FAQ>
                  <FAQ question="Is there any cost?">
                    <Typography variant="body1">
                      Health services provided by Chek Health are covered by Alberta Health Care Insurance Plan so there
                      is no cost to our users. Some cancer screening tests including CT scans may not be covered and you
                      may need to pay a fee to the radiology company providing the test.
                    </Typography>
                  </FAQ>
                  <FAQ question="Do I need to see my family physician to review results?">
                    <Typography variant="body1">
                      Our goal is to improve access to cancer screening. We do not replace the important health services
                      provided by your family physician. Any new or unexplained issues you are experiencing should be
                      addressed by your regular health provider. We follow-up all test results with you and make any
                      arrangements if further testing or referral is required. If you provide us your family physician
                      in the app, they will be copied on all test results.
                    </Typography>
                  </FAQ>
                  <FAQ question="How do I delete my account?">
                    <Typography variant="body1">
                      <ol>
                        <li>Download the Chek application to your iOS or Android device.</li>
                        <li>Sign in using the same Google or Apple information used to create the account.</li>
                        <li>On the home screen, tap the Profile button at the top right.</li>
                        <li>Tap the Account button on the My Profile screen.</li>
                        <li>Tap the Delete Account button.</li>
                        <li>
                          Tap the items in the checklist to confirm that you will no longer be under the care of Chek
                          Health, then tap Delete Account to delete your account.
                        </li>
                      </ol>
                      If you have any issues accessing the application or signing into your account, contact Chek Health
                      at{' '}
                      <Link
                        aria-label="Phone Chek Health"
                        href="tel:+18882527915"
                        rel="noopener"
                        title="Phone Chek Health"
                        sx={styles.surfaceText}
                      >
                        1-888-252-7915
                      </Link>
                      .
                    </Typography>
                  </FAQ>
                </Grid>
                <Grid item xs={12} md={6} sx={styles.s5ImageBox}>
                  <Box
                    component="img"
                    src="images/couple.png"
                    alt="couple"
                    sx={[
                      styles.s5Couple,
                      (!mobileM && styles.s5CoupleDefault) ||
                        (mobileS && styles.s5CoupleMobileS) ||
                        (mobileM && styles.s5CoupleMobileM),
                    ]}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={styles.sectionDark}>
              <Grid container>
                <Grid item xs={12} md={6} sx={styles.s6Grid1}>
                  <Typography sx={styles.s6Title} variant="h3">
                    Get Started Today
                  </Typography>
                  <Typography align={breakpointMdDown ? 'center' : 'left'} variant="body1">
                    Download today to get your cancer screening plan.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={styles.s6Grid2}>
                  <StoreButtons />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        {path === '/team' && (
          <Box sx={[styles.sectionLight, styles.sTeamSection]}>
            <Typography sx={styles.sTeamTitle} variant="subtitle2">
              OUR TEAM
            </Typography>
            <Box sx={styles.sTeamSubtitleBox}>
              {breakpointLgDown ? (
                <Typography align="center" sx={styles.sTeamSubtitle} variant="h4">
                  This is a new opportunity to improve cancer screening for all Albertans.
                </Typography>
              ) : (
                <Typography align="center" sx={styles.sTeamSubtitle} variant="h4">
                  This is a new opportunity to use technology to improve cancer screening for all Albertans.
                </Typography>
              )}
            </Box>
            <Grid container sx={styles.sTeamBox}>
              <Grid item xs={12} lg={5} sx={styles.sTeamImageBox}>
                <Box alt="Dr. Eddy" component="img" src="images/team_andrew.png" sx={styles.sTeamImage} />
                <Typography sx={styles.sTeamImageTitle} variant="subtitle1">
                  Dr. Andrew Eddy, MD, CCFP
                </Typography>
              </Grid>
              <Grid item xs={12} lg={7}>
                <Box sx={styles.sTeamTextBox}>
                  <Typography sx={[styles.sectionText, styles.sTeamText1, styles.sTeamText2]} variant="body1">
                    Dr. Eddy is from Calgary and completed his medical training at the University of Alberta in 2011.
                    Later that year he started his family medicine practice in Calgary. He has taken an interest in
                    improving efficiency and access in healthcare.
                  </Typography>
                  <Typography sx={[styles.sectionText, styles.sTeamText1]} variant="body1">
                    In 2014, Dr. Eddy received the Alberta College of Family Physicians Recognition of Excellence.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container sx={styles.sTeamBox}>
              <Grid item xs={12} lg={5} sx={styles.sTeamImageBox}>
                <Box alt="Kairn Crebbin" component="img" src="images/team_kairn.png" sx={styles.sTeamImage} />
                <Typography sx={styles.sTeamImageTitle} variant="subtitle1">
                  Kairn Crebbin
                </Typography>
                <Typography sx={styles.sTeamImageSubtitle} variant="subtitle2">
                  Health Care Manager
                </Typography>
              </Grid>
              <Grid item xs={12} lg={7}>
                <Box sx={styles.sTeamTextBox}>
                  <Typography sx={[styles.sectionText, styles.sTeamText1, styles.sTeamText2]} variant="body1">
                    Kairn has been living in Calgary since 1994. After 20+ years of administration experience, she
                    decided to return to school in 2016 to acquire her MOA Diploma, graduating with Honors at CDI.
                  </Typography>
                  <Typography sx={[styles.sectionText, styles.sTeamText1]} variant="body1">
                    Kairn&apos;s vast work experience and attention to detail makes her a very important addition to the
                    Chek Team.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        {path === '/clinic' && (
          <>
            <Box sx={styles.sectionLight}>
              <Grid container sx={styles.sClinicContainer}>
                <Grid item xs={12} md={6} sx={[styles.sClinicGrid, mobileM && styles.sClinicGridMobileM]}>
                  <Typography sx={[styles.sectionText, styles.surfaceText]} variant="h2">
                    Health Updates To Your Phone
                  </Typography>
                  <Box sx={styles.sClinicTextBox}>
                    <Typography sx={[styles.sectionText, styles.surfaceText, styles.sClinicSubtitle]} variant="body1">
                      Use your Chek mobile app to view your health updates.
                    </Typography>
                    <Typography sx={[styles.sectionText, styles.surfaceText, styles.sClinicSubtitle]} variant="body1">
                      If you have any questions contact us.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={styles.sClinicImageBox}>
                  <Box
                    component="img"
                    src="images/sofa_small.png"
                    alt="sofa"
                    sx={[
                      styles.sClinicCouple,
                      (!mobileM && styles.sClinicCoupleDefault) ||
                        (mobileS && styles.sClinicCoupleMobileS) ||
                        (mobileM && styles.sClinicCoupleMobileM),
                    ]}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={styles.sectionDark}>
              <Grid container>
                <Grid item xs={12} md={6} sx={styles.sClinicGrid1}>
                  <Typography sx={styles.sClinicTitle} variant="h3">
                    24/7 Health Service
                  </Typography>
                  {!mobileM && (
                    <Typography align={breakpointMdDown ? 'center' : 'left'} variant="body1">
                      Download today to get screening reminders, test requisitions and results delivered to your phone.
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={6} sx={styles.sClinicGrid2}>
                  <StoreButtons />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        <Box id="contactUs" sx={[styles.footer, styles.sectionLight]}>
          <NavLink
            handleClick={
              path === '/home'
                ? () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                : () => navigate('/home', { state: { scrollTo: 0 } })
            }
            title="Chek Logo"
          >
            <Box component="img" src="images/logo.svg" alt="chek logo" sx={styles.logo} />
          </NavLink>
          <Box sx={styles.footerContact}>
            <Typography variant="subtitle2">Chek Health Ltd.</Typography>
            <Typography variant="body2">335, 3917 University Ave NW</Typography>
            <Typography variant="body2">Calgary, AB T3B 6K3</Typography>
            <Link
              aria-label="Email Chek Health"
              href="mailto:chek@chekhealth.ca"
              rel="noopener"
              title="Email Chek Health"
              sx={styles.surfaceText}
            >
              <Typography variant="body2">chek@chekhealth.ca</Typography>
            </Link>
            <Link
              aria-label="Phone Chek Health"
              href="tel:+18882527915"
              rel="noopener"
              title="Phone Chek Health"
              sx={styles.surfaceText}
            >
              <Typography variant="body2">1-888-252-7915</Typography>
            </Link>
          </Box>
          <Box sx={styles.footerLegalLinks}>
            <Link
              aria-label="Privacy Policy"
              href="/privacy-policy"
              rel="noopener"
              title="Privacy Policy"
              sx={styles.surfaceText}
            >
              <Typography sx={styles.footerLegalText} variant="body2">
                PRIVACY POLICY
              </Typography>
            </Link>
            <Link
              aria-label="Terms and Conditions"
              href="/terms-and-conditions"
              rel="noopener"
              title="Terms and Conditions"
              sx={styles.surfaceText}
            >
              <Typography sx={styles.footerLegalText} variant="body2">
                TERMS OF SERVICE
              </Typography>
            </Link>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;
