import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';

const styles = {
  storeButtons: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    justifyContent: 'center',
  },
  storeButton: {
    height: { xs: 72, md: 64, lg: 72 },
    px: 1,
    py: 1,
  },
};

export default function StoreButtons(props) {
  const { sx } = props;

  return (
    <Box sx={[styles.storeButtons, ...(sx && Array.isArray(sx) ? sx : []), ...(sx && !Array.isArray(sx) ? [sx] : [])]}>
      <Link
        aria-label="Google Play Store"
        color="inherit"
        title="Google Play Store"
        href="https://play.google.com/store/apps/details?id=ca.chekhealth.chek"
        rel="noopener"
        sx={styles.link}
        target="_blank"
      >
        <Box component="img" src="images/googlePlayStore.png" alt="Google Play Store" sx={styles.storeButton} />
      </Link>
      <Link
        aria-label="Apple App Store"
        color="inherit"
        title="Apple App Store"
        href="https://apps.apple.com/ca/app/chek/id1637697054"
        rel="noopener"
        sx={styles.link}
        target="_blank"
      >
        <Box component="img" src="images/appleAppStore.png" alt="Applel App Store" sx={styles.storeButton} />
      </Link>
    </Box>
  );
}

StoreButtons.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

StoreButtons.defaultProps = {
  sx: null,
};
