import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import * as color from '../constants/colors';

const styles = {
  faq: {
    alignItems: 'start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  faqQuestion: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'start',
    py: 1.25,
  },
  faqButton: {
    color: color.colorSchemeLight.primary,
    mr: 2,
  },
  faqAnswer: {
    py: 1.25,
  },
  faqDivider: {
    backgroundColor: color.colorSchemeLight.onSurface,
    height: '1px',
    width: '100%',
    mb: 4,
  },
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function FAQ(props) {
  const { question, children } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={styles.faq}>
      <Box sx={styles.faqQuestion}>
        <ExpandMore
          sx={styles.faqButton}
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon fontSize="large" />
        </ExpandMore>
        <Typography variant="h6">{question}</Typography>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box sx={styles.faqAnswer}>{children}</Box>
      </Collapse>
      <Box sx={styles.faqDivider} />
    </Box>
  );
}

FAQ.propTypes = {
  children: PropTypes.node.isRequired,
  question: PropTypes.string.isRequired,
};

export default FAQ;
