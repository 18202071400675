export const colorSchemeLight = {
  primary: '#2e65ae',
  onPrimary: '#ffffff',
  primaryContainer: '#d6e3ff',
  onPrimaryContainer: '#001b3c',
  secondary: '#3d73b6',
  onSecondary: '#ffffff',
  secondaryContainer: '#d4e3ff',
  onSecondaryContainer: '#001c3a',
  tertiary: '#79a9d8',
  onTertiary: '#ffffff',
  tertiaryContainer: '#cee5ff',
  onTertiaryContainer: '#001d33',
  error: '#ba1a1a',
  onError: '#ffffff',
  errorContainer: '#ffdad6',
  onErrorContainer: '#410002',
  background: '000000',
  onBackground: '#ffffff',
  surface: '#ffffff',
  onSurface: '#000000',
  surfaceVariant: '#e0e2ec',
  onSurfaceVariant: '#43474e',
  outline: '#74777f',
  outlineVariant: '#c4c6cf',
  shadow: '#000000',
  surfaceTint: '#2e65ae',
  inverseSurface: '#2f3033',
  onInverseSurface: '#f1f0f4',
  inversePrimary: '#d6e2ff',
};

export const neutral100 = '#ffffffff';
export const neutral99 = '#fffdfbff';
export const neutral98 = '#fffaf9fd';
export const neutral95 = '#fff1f0f4';
export const neutral90 = '#ffe3e2e6';
export const neutral80 = '#ffc7c6ca';
export const neutral70 = '#ffababaf';
export const neutral60 = '#ff909094';
export const neutral50 = '#ff77777a';
export const neutral40 = '#ff5e5e62';
export const neutral35 = '#ff525256';
export const neutral30 = '#ff46474a';
export const neutral25 = '#ff3a3b3f';
export const neutral20 = '#ff2f3033';
export const neutral10 = '#ff1a1c1e';
export const neutral0 = '#ff000000';

export const neutralVariant100 = '#ffffffff';
export const neutralVariant99 = '#fffdfbff';
export const neutralVariant98 = '#fff9f9ff';
export const neutralVariant95 = '#ffeef0fa';
export const neutralVariant90 = '#ffe0e2ec';
export const neutralVariant80 = '#ffc4c6cf';
export const neutralVariant70 = '#ffa8abb4';
export const neutralVariant60 = '#ff8e9099';
export const neutralVariant50 = '#ff74777f';
export const neutralVariant40 = '#ff5b5e66';
export const neutralVariant35 = '#ff4f525a';
export const neutralVariant30 = '#ff43474e';
export const neutralVariant25 = '#ff383b43';
export const neutralVariant20 = '#ff2d3038';
export const neutralVariant10 = '#ff181c22';
export const neutralVariant0 = '#ff000000';
