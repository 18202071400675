/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';

const styles = {
  navLink: {
    cursor: 'pointer',
  },
};

function NavLink(props) {
  const { children, handleClick, sx, title } = props;

  return (
    <Link
      aria-label={title}
      onClick={() => {
        if (handleClick !== null) {
          handleClick();
        }
      }}
      sx={[styles.navLink, ...(sx && Array.isArray(sx) ? sx : []), ...(sx && !Array.isArray(sx) ? [sx] : [])]}
    >
      {children}
    </Link>
  );
}

NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.func,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  title: PropTypes.string,
};

NavLink.defaultProps = {
  handleClick: null,
  sx: null,
  title: null,
};

export default NavLink;
