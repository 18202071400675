import { createTheme } from '@mui/material';
import * as color from './colors';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1024,
      xl: 1200,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      html {
        -webkit-font-smoothing: unset;
        -moz-osx-font-smoothing: unset;
      }
      `,
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      light: color.neutral100,
      main: color.neutral100,
      dark: color.neutral100,
    },
    secondary: {
      light: color.neutral100,
      main: color.neutral100,
      dark: color.neutral100,
    },
    divider: color.colorSchemeLight.outline,
    background: {
      paper: color.colorSchemeLight.background,
      default: color.colorSchemeLight.background,
    },
    text: {
      primary: color.colorSchemeLight.onSurface,
      secondary: color.neutral30,
    },
  },
  shape: {
    borderRadius: 6,
  },
});

export function mixinFontSizes(fontSizes) {
  return {
    fontSize: fontSizes.xs,
    [theme.breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: fontSizes.lg,
    },
  };
}

theme.typography.h1 = {
  ...mixinFontSizes({ xs: '3.1438rem', sm: '3.5714rem', lg: '4rem' }),
  fontWeight: 300,
  fontFamily: 'Montserrat',
  letterSpacing: '0.125em',
  lineHeight: 1.1094,
  textSizeAdjust: '100%',
};

theme.typography.h2 = {
  ...mixinFontSizes({ xs: '2.25rem', sm: '2.8125rem', md: '3.25rem' }),
  fontWeight: 600,
  fontFamily: 'Montserrat',
  letterSpacing: '0.04em',
  lineHeight: 1.1094,
  textSizeAdjust: '100%',
};

theme.typography.h3 = {
  ...mixinFontSizes({ xs: '2rem', sm: '2.25rem', lg: '2.75rem' }),
  fontWeight: 600,
  fontFamily: 'Montserrat',
  letterSpacing: 'normal',
  lineHeight: 1,
  textSizeAdjust: '100%',
};

theme.typography.h4 = {
  ...mixinFontSizes({ xs: '1.9125rem', sm: '2.125rem', lg: '2.125rem' }),
  fontWeight: 700,
  fontFamily: 'Montserrat',
  letterSpacing: '0.5px',
  lineHeight: 1.1765,
  textSizeAdjust: '100%',
};

theme.typography.h5 = {
  ...mixinFontSizes({ xs: '1.3671rem', sm: '1.5625rem', lg: '1.5625rem' }),
  fontWeight: 400,
  fontFamily: 'Montserrat',
  letterSpacing: '0.25px',
  lineHeight: 1.28,
  textSizeAdjust: '100%',
};

theme.typography.h6 = {
  ...mixinFontSizes({ xs: '1.4063rem', sm: '1.5625rem', lg: '1.5625rem' }),
  fontWeight: 700,
  fontFamily: 'Montserrat',
  letterSpacing: '-0.14px',
  lineHeight: 1.28,
  textSizeAdjust: '100%',
};

theme.typography.subtitle1 = {
  ...mixinFontSizes({ xs: '1.125rem', sm: '1.25rem', lg: '1.25rem' }),
  fontWeight: 500,
  fontFamily: 'Montserrat',
  letterSpacing: '1.3px',
  lineHeight: 1.3,
  textSizeAdjust: '100%',
};

theme.typography.subtitle2 = {
  fontSize: '1rem',
  fontWeight: 600,
  fontFamily: 'Montserrat',
  letterSpacing: '1.3px',
  lineHeight: 1.25,
  textSizeAdjust: '100%',
};

theme.typography.body1 = {
  fontSize: '1.3125rem',
  ...mixinFontSizes({ xs: '1.1563rem', sm: '1.3125rem', lg: '1.3125rem' }),
  fontWeight: 400,
  fontFamily: 'Montserrat',
  letterSpacing: '0.25px',
  lineHeight: 1.5238,
  textSizeAdjust: '100%',
};

theme.typography.body2 = {
  ...mixinFontSizes({ xs: '0.8750rem', sm: '0.9375rem', lg: '0.9375rem' }),
  fontWeight: 400,
  fontFamily: 'Montserrat',
  letterSpacing: 'normal',
  lineHeight: 1.4,
  textSizeAdjust: '100%',
};

theme.typography.caption = {
  fontSize: '1.125rem',
  fontWeight: 600,
  letterSpacing: '0.4px',
  lineHeight: 1.3333,
  textSizeAdjust: '100%',
};

theme.typography.button = {
  fontWeight: 700,
  ...mixinFontSizes({ xs: '0.8750rem', sm: '0.9375rem', lg: '0.9375rem' }),
  textSizeAdjust: '100%',
};

export default theme;
